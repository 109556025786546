export function numberWithCommas(value) {
  const [integerPart, fractionPart = ''] = value.toString().split('.');
  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (fractionPart !== '' ? `.${fractionPart}` : '');
}

export function isEmpty(value) {
  return value === undefined || value === null || value === '';
}

export function isEmptyObject(obj) {
  if (obj !== null && obj !== undefined) return Object.keys(obj).length === 0;
  return true;
}
