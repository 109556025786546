import React, { useState, useRef, useEffect } from 'react';
import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { isEmpty } from '../..//utils/utils';

function SearchTerm(props) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [text, setIstext] = useState('');
  const [hideLabelSearch, setHideLabelSearch] = useState(false);
  const { hl } = props;
  const ref = useRef();


  useEffect(() => {
    if (ref.current.state.initialItem == null) {
      setHideLabelSearch(true);
    } else {
      setHideLabelSearch(false);
    }
  });

  const handleSearch = query => {
    setIsLoading(true);
    setIstext(query);
    const url = `${process.env.ROOT_API}term/auto-complete-text`;
    fetch(`${url}?text=${encodeURI(query)}&pageIndex=1&pageSize=10`)
      .then(resp => resp.json())
      .then(
        resp => {
          const options = resp.results.map(i => ({
            id: i.id,
            name: i.title,
            slug: `${i.slug}-${i.id}`,
          }));

          setOptions(options);
          setIsLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          // setIsLoading(true);
        },
      );
  };

  function handleSearchChange(selectedOptions) {
    if (selectedOptions && selectedOptions.length > 0) {
      const { slug, isEng } = selectedOptions[0];
      router.push({
        pathname: `/${slug}`,
        query: {
          ...(isEng === true && { hl: isEng }),
        },
      });
    }
  }

  function handleSearchQuery(e) {
    if (text.length > 0) {
      setIsLoading(true);
      // setText(text);
      // props.history.push(`/thuat-ngu?keyword=${text}&language=vn`);
      router.push({
        pathname: '/thuat-ngu',
        query: {
          text: `${text}`,
          ...(hl === 'en' && { hl }),
        },
      });
    }
  }
  // ?keyword=${text}&language=vn
  function SearchLoading(props) {
    if (!isLoading) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="svg-icon fill-current"
        >
          <circle cx={11} cy={11} r={8} />
          <line x1={21} y1={21} x2="16.65" y2="16.65" />
        </svg>
      );
    }
    return (
      <Spinner animation="border" variant="secondary" style={{ width: ' 20px', height: '20px', borderWidth: ' 2px' }} />
    );
  }

  function onTypeAheadKeyDown(e) {
    if (e.key === 'Enter') {
      handleSearchQuery();
    }
    return false;
  }

  return (
    <>
      <div className="h-search__wrapper">
        <div className={`h-search__form ${hideLabelSearch ? 'hide-dropSearch' : ''}`}>
          <AsyncTypeahead
            id="async-example"
            isLoading={isLoading}
            labelKey="name"
            minLength={1}
            onSearch={handleSearch}
            options={options}
            placeholder="Bạn cần tìm thuật ngữ gì ..."
            defaultInputValue={!isEmpty(props.text) ? props.text : ''}
            promptText="Gõ từ khóa"
            searchText="Đang tìm..."
            emptyLabel="Đang tìm..."
            highlightOnlyResult={true}
            ref={ref}
            inputProps={{
              className: 'h-search__input search-quick-input',
            }}
            onKeyDown={onTypeAheadKeyDown}
            onChange={handleSearchChange}
            renderMenuItemChildren={(option, props) => <Highlighter search={props.text}>{option.name}</Highlighter>}
          />
          <div className="h-search__icon" onClick={() => handleSearchQuery()}>
            <SearchLoading isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchTerm;
