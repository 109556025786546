import { memo } from 'react';
import Link from 'next/link';
import { TrendingUp, ChevronLeft, Phone, Menu, X } from 'react-feather';

const TheTopBar = () => {
  return (
    <>
      <header className="site-header">
        <div className="nav-row nav-row-upper">
          <div className="container overflow-hidden ">
            <div className="row align-content-around nav-row-upper-height align-items-center">
              <div className="col-auto ">
                <a href="https://vietnambiz.vn/" className="nav-row-back link-white ">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 426.667 426.667">
                    <path d="M213.333 32L0 224h64v170.667h106.667v-128H256v128h106.667V224h64z" />
                  </svg>
                </a>
                {/* <a href="https://vietnambiz.vn/" className="vnb-back">
                  <ChevronLeft />
                  <div>
                     <b> Vietnambiz</b>
                  </div>
                </a> */}
              </div>

              <div className="col text-center">
                <div className="branding">
                  <a href= "/" className="logo">
                    <img src="/static/img/logo/logo-new.png" alt="VietnamBiz" width={150} />
                  </a>
                </div>
              </div>

              <div className="col-auto ">
                <div className="nav-row-back" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default memo(TheTopBar);
