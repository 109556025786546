import { memo } from 'react';
import Link from 'next/link';

const TheFooter = () => {
  return (
    <>
      <footer className="bg-light p-0 ">
        <div className="footer-contain">
          <div className="container ">
            <div className="row  align-items-center">
              <div className="col-auto">
                <Link href="/">
                  <a className="d-block mt-1">
                    <img src="/static/img/logo/logo-dark.png" alt="VietnamBiz" width={150}/>
                  </a>
                </Link>
              </div>
              <div className="col-auto ">
                <div className="footer-left pl-lg-3">
                  <div className="mb-0 footer-name"> Từ điển tra cứu thuật ngữ kinh tế của <b>VietnamBiz</b> </div>
                  <div>
                    Liên hệ: <a href="mailto:info@vietnambiz.vn">info@vietnambiz.vn</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default memo(TheFooter);
