import Link from 'next/link';
import TheTopBar from './TheTopBar';
import TheFooter from './TheFooter';

function Layout({ children }) {
  return (
    <React.Fragment>
      <TheTopBar />
      {children}
      <TheFooter />
    </React.Fragment>
  );
}

export default Layout;
